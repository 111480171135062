<template>
  <b-card>
    <v-tree
      ref="tree"
      class="list-permission"
      :data="data"
      :draggable="true"
      :halfcheck="true"
      :multiple="isFullPermission ? true : false"
      :tpl="tpl"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import { VTree } from 'vue-tree-halower';
import { ref, toRefs, watch } from '@vue/composition-api';
import { permissionList } from '@/apis/apiRole_Permission';
import permissionCode from '@/constants/permissionCode';

export default {
  components: {
    VTree,
    BCard,
  },
  props: {
    perCheck: {
      type: Array,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isFullPermission: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    // danh sach permission cua he thong
    const { perCheck } = toRefs(props);
    const data = ref([]);
    const permissions = ref([]);
    const groupPermission = (listPer) => {
      const res = [];
      const formatArrayPer = listPer?.reduce((result, item) => {
        result[item.group] = result[item.group] || [];
        result[item.group].push(item);
        return result;
      }, {});
      if (formatArrayPer) {
        for (const [key, value] of Object.entries(formatArrayPer)) {
          res.push({
            group: key,
            value,
          });
        }
      }
      return res;
    };

    const createChildren = (listChild, perRole) => {
      const res = [];
      listChild.forEach((child) => {
        const role =
          perRole.find((r) => (r.permissionId ? r.permissionId === child.id : r.id === child.id)) ||
          {};
        res.push({
          title: child.name,
          // id: child.id,
          checked: !!role.permissionId || !!role.id,
          children: [
            {
              id: `self_${child.id}`,
              checked: role.limited === 'self',
              title: 'cá nhân',
            },
            {
              id: `group_${child.id}`,
              checked: role.limited === 'group',
              title: 'Nhóm',
            },
            {
              id: `all_${child.id}`,
              checked: role.limited === 'all',
              title: 'Tất cả',
            },
          ],
          // expanded: true,
        });
      });
      return res;
    };

    const updatePermissionsCheck = (value) => {
      data.value = [];
      // permission cua role
      value = groupPermission(value);
      permissions.value.forEach((item) => {
        const perRole = value.find((val) => val.group === item.group) || {};
        // permission duoc check
        if (perRole.group) {
          data.value.push({
            title: item.group,
            checked: perRole.value.length === item.value.length,
            halfcheck: perRole.value.length < item.value.length,
            children: createChildren(item.value, perRole.value),
            expanded: !props.isFullPermission,
          });
        } else {
          // permission khong duoc check
          data.value.push({
            title: item.group,
            children: createChildren(item.value, []),
          });
        }
      });
    };

    const getPermission = () => {
      if (props.isFullPermission) {
        permissionList()
          .then((res) => {
            permissions.value = groupPermission(res.data.data);
            updatePermissionsCheck(perCheck.value);
          })
          .catch((err) => console.log(err));
      } else {
        permissions.value = groupPermission(perCheck.value);
        updatePermissionsCheck(perCheck.value);
      }
    };

    // fetch list permissions
    getPermission();

    watch(perCheck, (value) => {
      updatePermissionsCheck(value);
    });

    return {
      data,
      permissions,
      getPermission,
      groupPermission,
      createChildren,
      permissionCode,
    };
  },
  methods: {
    tpl(...args) {
      const { 0: node, 2: parent, 3: index, 4: props } = args;
      let titleClass = node.checked ? 'node-title node-selected' : 'node-title';
      if (node.searched) titleClass += ' node-searched';
      if (props.level === 1 && node.checked === true) {
        node.children.forEach((child) => {
          if (child.children[0].checked === true) {
            child.children.forEach((item, idx) => {
              if (idx !== 0) {
                item.checked = false;
              }
            });
          }
        });
      }
      if (props.level === 3 && node.checked === true) {
        parent.children.forEach((element, idx) => {
          if (index !== idx) {
            element.checked = false;
          }
        });
        parent.checked = true;
      }
      return (
        <span>
          <span
            class={titleClass}
            domPropsInnerHTML={node.title}
            // onClick={() => {
            //   this.$refs.tree.nodeSelected(node);
            // }}
          ></span>
        </span>
      );
    },
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/tree.scss';
.list-permission {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  .node-title {
    padding: 0;
    margin: 0;
  }
  .halo-tree {
    .node-title {
      padding: 0;
      margin: 0;
    }
    .check {
      left: -2px;
    }
    li::before {
      display: block;
    }
    li::after {
      display: block;
      width: 7px;
    }
    li {
      width: 100%;
    }
    // .leaf {
    //   display: inline-block;
    //   width: 33%;
    // }
  }
  li::before,
  li::after {
    display: none;
  }
  li {
    width: 30%;
    padding-left: 0;
    margin-left: 15px;
  }
  .inputCheck {
    &.box-checked:after {
      background-color: #28c76f;
      color: #fff;
    }
  }
}
</style>

import jwt from '@/auth/jwt/useJwt';

export function roleList() {
  return jwt.axiosIns.get('/roles');
}

export function getPermissionRole(id) {
  return jwt.axiosIns.get(`/roles/${id}/permissions`);
}

export function createRole(data) {
  return jwt.axiosIns.post('/roles', data);
}

export function updatePermission(roleId, data) {
  return jwt.axiosIns.put(`/roles/${roleId}/permissions`, data);
}

export function permissionList() {
  return jwt.axiosIns.get('/permissions');
}

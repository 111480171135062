<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="changePassForm">
      <b-form @submit.prevent="handelSubmit">
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group :label="$t('Old Password')" label-for="account-old-password">
              <validation-provider
                #default="{ errors }"
                :name="$t('Old Password')"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-old-password"
                    v-model="passwordValueOld"
                    name="old-password"
                    :type="passwordFieldTypeOld"
                    :placeholder="$t('Old Password')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group label-for="account-new-password" :label="$t('New Password')">
              <validation-provider
                #default="{ errors }"
                :name="$t('New Password')"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="newPasswordValue"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    :placeholder="$t('New Password')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
              label-for="account-retype-new-password"
              :label="$t('Retype New Password')"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('Retype New Password')"
                vid="retypeNewPassword"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="RetypePassword"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    :placeholder="$t('Retype New Password')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <div class="mt-1 mr-1">
              <b-overlay
                :show="loading"
                opacity="0.6"
                spinner-variant="primary"
                class="width-max-content"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                >
                  {{ $t('Save') }}
                </b-button>
              </b-overlay>
            </div>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import useJwt from '@/auth/jwt/useJwt';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BButton,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // validation rules
      required,

      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      loading: false,
    };
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password';
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password';
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === 'password' ? 'text' : 'password';
    },
    isSamePass(changePassForm) {
      if (this.newPasswordValue === this.RetypePassword) {
        return true;
      }
      changePassForm.setErrors({
        retypeNewPassword: 'Mật khẩu nhập lại không khớp',
      });
      return false;
    },
    async handelSubmit() {
      const { changePassForm } = this.$refs;
      const isValid = await changePassForm.validate();
      if (isValid && this.isSamePass(changePassForm)) {
        this.loading = true;
        useJwt
          .changePass({
            password: this.passwordValueOld,
            newPassword: this.newPasswordValue,
          })
          .then((res) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Đổi mật khẩu ',
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: 'Đổi mật khẩu thành công!',
              },
            });
          })
          .catch((err) => {
            this.loading = false;
            if (err.response?.status === 422) {
              const { errors } = err.response.data;
              if (errors) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Đổi mật khẩu ',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: this.$t('Invalid data'),
                  },
                });
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Đổi mật khẩu ',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: 'Mật khẩu cũ không chính xác!',
                  },
                });
              }
            }
          });
      }
    },
  },
};
</script>

<style scoped>
.width-max-content {
  width: max-content;
}
</style>

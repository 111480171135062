<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <!-- <b-img ref="previewEl" rounded :src="userInfo.avatar" height="80" /> -->
          <b-avatar
            ref="previewEl"
            :src="avatar"
            :text="avatarText(userInfo.fullName)"
            size="80px"
            variant="light-primary"
            rounded
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="inputImageRenderer"
        />
        <!--/ upload button -->
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group :label="$t('Username')" label-for="account-username">
            <b-form-input
              :value="userInfo.username"
              disabled
              :placeholder="$t('Username')"
              name="username"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group :label="$t('Full name')" label-for="account-name">
            <b-form-input
              :value="userInfo.fullName"
              disabled
              name="name"
              :placeholder="$t('Full name')"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="E-mail" label-for="account-e-mail">
            <b-form-input :value="userInfo.email" disabled name="email" placeholder="Email" />
          </b-form-group>
        </b-col>
        <!-- <b-col cols="12">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-2 mr-1">
            {{ $t('Save') }}
          </b-button>
        </b-col> -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BAvatar,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
import { ref } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';

export default {
  components: {
    BButton,
    BAvatar,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    userInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      profileFile: null,
    };
  },
  methods: { avatarText },
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);
    const avatar = ref(null);
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, (base64) => {
      // eslint-disable-next-line no-param-reassign
      avatar.value = base64;
    });
    return {
      avatar,
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
};
</script>
